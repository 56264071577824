import { useForm, useFormState } from "react-final-form";

import { Stack } from "@bps/fluent-ui";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { FieldsSpy } from "@ui-components/form/FieldsSpy.tsx";
import { useDebounce } from "@ui-components/hooks/useDebounce.ts";

import {
  InactivityPinFormValues,
  names,
  PIN_LENGTH
} from "./InactivityPinForm.types.ts";
import { InactivityPinFormActions } from "./InactivityPinFormActions.tsx";
import { InactivityPinFormFields } from "./InactivityPinFormFields.tsx";
import { InactivityPinFormHeader } from "./InactivityPinFormHeader.tsx";

export const InactivityPinForm = () => {
  const { change, getState, submit } = useForm<InactivityPinFormValues>();
  const { submitSucceeded } = useFormState({
    subscription: { submitSucceeded: true }
  });

  const onChange = () => {
    const { invalidPin, showPin, ...pinValues } = getState().values;
    const pin = Object.values(pinValues).join("");
    invalidPin && change(nameof("invalidPin"), false);
    // Checking !invalidPin to prevent resubmit when showPin is checked/unchecked after an invalid pin is submitted
    if (pin.length === PIN_LENGTH) {
      submit();
    }
  };

  // don't debounce on first submission
  const onChangeHandler = useDebounce(onChange, 1000);
  return (
    <Stack horizontalAlign="center">
      <InactivityPinFormHeader title="Your screen has been locked" />

      <InactivityPinFormFields />

      {/* Actual form submit if PIN is valid  */}
      <FieldsSpy
        fieldNames={names}
        onChange={submitSucceeded ? onChangeHandler : onChange}
      />
      <InactivityPinFormActions />
    </Stack>
  );
};
