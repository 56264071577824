import { useMsalBrowserStorage } from "app-shell/session/hooks/useMsalBrowserStorage.ts";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";

import { confirm, Link, Stack } from "@bps/fluent-ui";
import { login } from "@bps/http-client";
import { appContext } from "@libs/config/app-context.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { useAuthorisationContext } from "../../context/AuthorisationContext.tsx";

export const InactivityPinFormActions: FunctionComponent = observer(() => {
  const { core, notification } = useStores();

  const { logoutAllClients } = useAuthorisationContext();
  const { wipeInteractionInProgress } = useMsalBrowserStorage();

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 16 }}
      styles={{ root: { marginTop: 50 } }}
    >
      <Link
        onClick={async () => {
          const isConfirmed = await confirm({
            confirmButtonProps: {
              text: "Sign out"
            },
            cancelButtonProps: {
              text: "Stay signed in"
            },
            dialogContentProps: {
              title: "Confirm sign out",
              subText:
                "To sign in a different user, you will need to sign out of this account.\n" +
                "Any unsaved changes will be lost."
            }
          });

          if (isConfirmed) {
            await logoutAllClients();
          }
        }}
      >
        Sign in as a different account
      </Link>
      <Link
        onClick={async () => {
          try {
            wipeInteractionInProgress();
            await login({
              authority: appContext.config.b2cAuthentication.resetPinPolicy,
              extraQueryParameters: {
                email: core.user?.email ?? "",
                tid: core.tenantDetails?.id ?? ""
              },
              onRedirectNavigate: url => {
                const link = document.body.appendChild(
                  document.createElement("a")
                );
                link.target = "_blank";
                link.href = url;
                link.dispatchEvent(new MouseEvent("click"));
                return false;
              }
            });
          } catch (e) {
            notification.error(e.message);
          }
        }}
      >
        Forgot my PIN
      </Link>
    </Stack>
  );
});
